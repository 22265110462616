import * as React from "react";
import { Link } from "gatsby";

import Bridge from "../../components/Bridge.js";
// import Ch from "../../components/Chord.js";
// import Chorus from "../../components/Chorus.js";
import Columns from "../../components/Columns.js";
import { Helmet } from "react-helmet";
import Layout from "../../components/Layout";
// import PreChorus from "../../components/PreChorus.js";
import Verse from "../../components/Verse.js";

export default function LockdownLove() {
  return (
    <Layout>
      <Helmet title="Lockdown Love (You Know Me) | Michael Weslander" />

      <h1>Lockdown Love (You Know Me)</h1>
      <h2>Michael Weslander</h2>
      <h4>Key: E (F w/capo)</h4>
      <h4>Tempo: 88.5</h4>
      <h4>Capo: 1</h4>
      <Link to="/">List</Link>

      <Columns columns="2">

        <Verse>
          <p>Each night I set aside</p>
          <p>A little bit of love for the days we might fight</p>
          <p>Oh but I've been gambling with the savings</p>
          <p>Lockdown love is hazy</p>
          <p>We'll either own the world or lose it all, but baby</p>
          <p>You know me</p>
          <p>You know me</p>
        </Verse>

        <Verse>
          <p>Each night I set aside</p>
          <p>A little bit of love for the days we might fight</p>
          <p>Oh but I've been spending more than making</p>
          <p>Lockdown love is lazy, sometimes</p>
          <p>Comfortable feels better than amazing</p>
          <p>You know me</p>
          <p>You know me</p>
        </Verse>

        <Bridge>
          <p>I’ll go</p>
          <p>Dancing on the edge alone</p>
          <p>Light a match & burn down all I know</p>
          <p>Dancing on the edge in time</p>
          <p>Can I hold the line?</p>
        </Bridge>

        <Bridge>
          <p>Falling through the days and weeks</p>
          <p>We may talk but we don’t speak</p>
          <p>Falling feeling fast and slow, like a</p>
          <p>A feature length episode</p>
        </Bridge>

        <Verse>
          <p>Each night I set aside</p>
          <p>A little bit of love for any old time</p>
          <p>Oh we'll make it through the ending</p>
          <p>Lockdown love is bending</p>
          <p>You can pick the show tonight depending</p>
          <p>You know me</p>
          <p>You know me</p>
        </Verse>

      </Columns>
    </Layout>
  );
}
